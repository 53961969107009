import { useAuth0 } from '@auth0/auth0-react'
import {
  DollarIcon,
  Link,
  QuestionmarkIcon,
} from '@maersktankersdigital/web-components'
import { Box, Fade, Typography } from '@mui/material'
import { useRouteError } from 'react-router-dom'
import { Auth0ProviderWithConfig } from '~components/auth0-provider-with-config'
import LoadComponent from '~components/molecules/load-component/load-component'
import { shouldRefresh, useErrorHandler } from '~components/page-unavailable'

const suggestions = [
  { label: 'help', link: '/panel/help', icon: <QuestionmarkIcon /> },
  { label: 'financials', link: '/panel/financials', icon: <DollarIcon /> },
]

export default function ErrorPage() {
  const error = useRouteError()
  console.error('router error ', error)
  useErrorHandler()

  const { isAuthenticated } = useAuth0()

  if (shouldRefresh()) {
    return <LoadComponent />
  }

  return (
    <Auth0ProviderWithConfig>
      <Box
        component="main"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          mx: { xs: 4, md: 0 },
        }}
      >
        <Box>
          <Typography variant="h1" sx={{ mb: 5 }}>
            We are sorry!
          </Typography>
          <Typography sx={{ maxWidth: '520px' }}>
            The page you are looking for has disappeared. It may have been
            removed, may have changed its name or is temporarily unavailable.
          </Typography>
          {isAuthenticated ? (
            <Fade in>
              <Box>
                <Typography sx={{ mt: 5 }}>
                  Try one of these sites instead:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pl: 4,
                    pt: 2,
                  }}
                >
                  {suggestions.map((suggestion) => {
                    return (
                      <Box key={suggestion.label} sx={{ py: 1 }}>
                        <Link link={suggestion.link} label={suggestion.label} />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Fade>
          ) : (
            // placeholder
            <Box sx={{ height: '112px' }} />
          )}
        </Box>
      </Box>
    </Auth0ProviderWithConfig>
  )
}
