import styled from 'styled-components'

export const StyledSpinner = styled.svg.attrs({
  'aria-label': 'loading',
})`
  animation: rotation 0.7s infinite linear;
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
`
