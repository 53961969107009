import { Header } from '@maersktankersdigital/web-components'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useConstructLinkWithPool } from '~hooks/use-construct-link-with-pool'
import { getFeatureToggle, calendarEnabled } from '~utils/feature-toggles'

export function useSubheaderLinks() {
  const calendarLink = useConstructLinkWithPool('/panel/calendar/:pool')
  const newsLink = useConstructLinkWithPool('/panel/news/:pool')
  const documentsLink = useConstructLinkWithPool('/panel/documents/:pool')
  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isPoolPartner = useHasScope(ExactScopeName.poolPartner)
  const calendarFlagEnabled = getFeatureToggle(calendarEnabled)

  const subheaderPages: Header[] = [
    ...(!isTechnicalManager
      ? [
          {
            title: 'news',
            link: newsLink,
          },
        ]
      : []),
    ...(!isTechnicalManager && calendarFlagEnabled
      ? [
          {
            title: 'calendar',
            link: calendarLink,
          },
        ]
      : []),
    ...(!isTechnicalManager
      ? [
          {
            title: 'documents',
            link: documentsLink,
          },
        ]
      : []),
    {
      title: 'help',
      link: '/panel/help',
    },
    {
      title: 'contact',
      link: 'panel/contact',
    },
    {
      title: 'profile',
      link: '/profile',
    },
  ]

  if (isPoolPartner || isTechnicalManager || isMtUser) {
    return subheaderPages
  }

  return [
    {
      title: 'profile',
      link: '/profile',
    },
  ]
}
